<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <div class="flex-column">
                <div class="d-flex">
                    <!--<div class="question-number">3-</div>-->
                    <div class="flex-column padding-div">
                        <div class="fs-32px fw-700 color-dark" v-if="fluxo=='menor18' || fluxo=='convidadomenor18' || fluxo=='aberturamenor18'">O dependente é uma pessoa politicamente exposta?</div>
                        <div class="fs-32px fw-700 color-dark" v-else>Você é uma pessoa politicamente exposta?</div>
                        <div class="valia-tooltip">
                            Saiba mais
                            <span class="valia-tooltiptext">É considerada Pessoa Politicamente Exposta quem desempenha ou tenha desempenhado, nos últimos cinco anos, no Brasil ou no estrangeiro, cargo, emprego ou função pública relevante, assim como seus familiares (parentes na linha reta até o segundo grau, cônjuge, companheiro(a), enteado(a)) e pessoas de seu relacionamento próximo, conforme disposto nos artigos 15 e 16 da <a href="https://www.in.gov.br/en/web/dou/-/instrucao-normativa-previc-n-34-de-28-de-outubro-de-2020-285633819" class="valia-link-white"  target="_blank">INSTRUÇÃO NORMATIVA PREVIC Nº 34, DE 28 DE OUTUBRO DE 2020</a>.</span>
                        </div>
                        <div class="mt-5">
                            <div class="select-boxes-row">
                                <div v-bind:class="(selection == true ? 'select-box select-box-1 active': 'select-box select-box-1')" @click="select(true)">
                                    <p class="mb-0">Sim</p>
                                </div>
                                <div v-bind:class="(selection == false ? 'select-box select-box-2 active' : 'select-box select-box-2')" @click="select(false)">
                                    <p class="mb-0">Não</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </transition>
</template>


<script>
    import 'bootstrap';
    import { mapState } from "vuex";
    import CorporativoAPI from '../../services/CorporativoAPI';
    export default {
        name: 's03-ppe',
        props: ['pessoa', 'visible', 'setpessoadata', 'fluxo'],
        data: () => ({
            isActive: false,
            loading: false,
            selection: null,
            step: 1,
            valInicial: false,
            pessoaCorporativa: null,
            isParticipante: false,
            isRepresentante: false,
            isDependente: false,
            detalhes: null,
            detalhesEndereco: null

        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        computed: {
            ...mapState(["pessoaCorporativo"]),
            ...mapState(["token"]),
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;

                if (this.isActive) {
                    this.habilitaOpcoes();
                }
            },
            select(val) {
                this.pessoa["perfil-pessoal"].ppe = (val == true ? "S" : "N");
                this.next();
            },
            obterIncidencia(faixas, valorString) {

                function converterStringParaNumero(valorString) {
                    const valorNumerico = parseFloat(valorString.replace(',', '.').replace(/[^\d.]/g, ''));
                    return isNaN(valorNumerico) ? 0 : valorNumerico;
                }


                // Converter a string de valor em um número
                const valor = converterStringParaNumero(valorString);

                // Encontre a incidência com base no valor fornecido
                for (const faixa of faixas) {
                    const valoresFaixa = faixa.descricao.split(" a ").map(valor => converterStringParaNumero(valor));

                    if (valoresFaixa.length === 2) {
                        if (valor >= valoresFaixa[0] && (valor <= valoresFaixa[1] || valoresFaixa[1] === 0)) {
                            return faixa.incidencia;
                        }
                    } else if (valoresFaixa.length === 1 && valor >= valoresFaixa[0]) {
                        return faixa.incidencia;
                    }
                }

                // Se nenhum resultado for encontrado, retorne um valor padrão
                return "47"; //faixa de fallback
            },
            async habilitaOpcoes() {

                if (this.pessoa["perfil-pessoal"] === undefined || this.pessoa["perfil-pessoal"] == null)
                    this.pessoa["perfil-pessoal"] = {};

                if (this.pessoa["perfil-pessoal"] !== undefined && this.pessoa["perfil-pessoal"].ppe !== undefined && this.pessoa["perfil-pessoal"].ppe != null) {
                    if (this.pessoa["perfil-pessoal"].ppe == "S")
                        this.selection = true;

                    if (this.pessoa["perfil-pessoal"].ppe == "N")
                        this.selection = false;
                }

                this.pessoa["perfil-plano"]["regime-tributacao"] = "1";

                if (this.setpessoadata != 'false') {   //proprio

                    this.pessoa["perfil-plano"]["faixa-salarial"] = "47"; //temporariamente ate buscarmos a faixa salarial

                    if (this.pessoaCorporativo != null) {
                        if (this.pessoaCorporativo.busca == "Participante") {
                            this.isParticipante = true;
                        }
                        else if (this.pessoaCorporativo.busca == "Representante") {
                            this.isRepresentante = true;
                        }
                        else if (this.pessoaCorporativo.busca == "Dependente") {
                            this.isDependente = true;
                        }
                    } 

                    var dcrNatural = ""; 
                    var codSexo = "";
                    if (this.pessoaCorporativo != null) {
                        //o proprio so pode ser participante.
                        if (this.isParticipante) {
                            await CorporativoAPI.getDetalhesParticipante(this.pessoaCorporativo.codempresa, this.pessoaCorporativo.registro, this.pessoaCorporativo.matricula, this.token).then(response => {
                                this.detalhes = response.data.Resultado;
                                this.detalhesEndereco = response.data.Endereco;
                            })
                                .catch(e => {
                                    console.log(e);
                                });
                            if (this.detalhes !== undefined) {
                                dcrNatural = this.detalhes["dcr_natural_emprg"];
                                codSexo = this.detalhes["cod_sexo_emprg"]; 
                            }
                        }
                    } 
                    if (dcrNatural !== undefined && dcrNatural != null)
                        this.pessoa["perfil-pessoal"].naturalidade = dcrNatural;

                    if (codSexo !== undefined && codSexo != null && codSexo!="")
                        this.pessoa["perfil-pessoal"].sexo = codSexo;
                    
                    if (this.pessoaCorporativo != null && this.detalhes["vlr_salar_emprg"] !== undefined && this.detalhes["vlr_salar_emprg"]!=null) {
                        await CorporativoAPI.getFaixaSalarialAll()
                            .then(response => {
                                var itemsFaixaSalarial = response.data.resultado;
                                this.pessoa["perfil-plano"]["faixa-salarial"] = this.obterIncidencia(itemsFaixaSalarial, this.detalhes["vlr_salar_emprg"]);
                            })
                            .catch(e => {
                                console.log(e);
                            });
                    }
                }

            },
            next: function () {
                this.$emit('next');
            },
            back: function () {
                this.$emit('back');
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

